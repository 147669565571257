<template>
  <div>
    <b-row>
      <b-col>
        <AnalyticsDateSelector title="MOST FREQUENT CLICKS DASHBOARD" />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card class="d-flex flex-column h-100">
          <b-row>
            <b-col
              cols="12"
              class="text-center mb-2"
            >
              <b-img
                :src="require('@/assets/images/logo/golog_b2bpro.png')"
                width="250px"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              lg="6 border-right"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(item, index) in fakeData1"
                  :key="index"
                  class="border-0 d-flex justify-content-between"
                >
                  <span>{{ item.label }}</span>
                  <span>{{ item.value }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(item, index) in fakeData2"
                  :key="index"
                  class="border-0 d-flex justify-content-between"
                >
                  <span>{{ item.label }}</span>
                  <span>{{ item.value }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="6"
      >
        <b-card class="d-flex flex-column h-100">
          <b-row>
            <b-col
              cols="12"
              class="text-center mb-2"
            >
              <b-img
                :src="require('@/assets/images/logo/golog_smarttruck.png')"
                width="345px"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              lg="6 border-right"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(item, index) in fakeData1"
                  :key="index"
                  class="border-0 d-flex justify-content-between"
                >
                  <span>{{ item.label }}</span>
                  <span>{{ item.value }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <b-list-group>
                <b-list-group-item
                  v-for="(item, index) in fakeData2"
                  :key="index"
                  class="border-0 d-flex justify-content-between"
                >
                  <span>{{ item.label }}</span>
                  <span>{{ item.value }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col class="text-center">
        <b-button
          variant="primary"
          @click="$router.push({ name: 'dashboard' })"
        >
          Go back
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { AnalyticsDateSelector } from '@/components'

export default {
  name: 'ClicksAnalytics',
  components: {
    AnalyticsDateSelector,
  },
  data() {
    const fakeData1 = [
      { label: 'Dashboard', value: 1230 },
      { label: 'My Profile', value: 1503 },
      { label: 'Create Delivery', value: 1230 },
      { label: 'Delivery History', value: 1002 },
      { label: 'Track & Trace', value: 293 },
      { label: 'My Cart', value: 1283 },
    ]
    const fakeData2 = [
      { label: 'Order ID', value: 2837 },
      { label: 'Download Document', value: 1503 },
      { label: 'Proof of Delivery', value: 738 },
      { label: 'GRN', value: 593 },
      { label: 'Login', value: 1325 },
      { label: 'Sign Up', value: 2983 },
    ]
    return {
      fakeData1,
      fakeData2,
    }
  },
}
</script>
